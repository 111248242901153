@import "~bootswatch/dist/quartz/variables";

$body-bg-image: linear-gradient(45deg, #043639, #130D40, #300535, #09253C);

$primary: rgba(196, 41, 110, 0.9);
$info: rgba(13, 202, 240, 0.9);
$warning: rgba(255, 193, 7, 0.9);
$danger: rgba(253, 126, 20, 0.9);
$light: rgba(233, 236, 239, 0.9);

$main-text-color: rgba(238, 238, 238, 0.9);
$body-color: $main-text-color;
$card-title-color: $main-text-color;
$card-color: $main-text-color;
$navbar-dark-brand-color: $main-text-color;
$navbar-dark-brand-hover-color: rgba(238, 238, 238);

